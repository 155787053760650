import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import { getCatalogButton, getPublishedMlModelAndMlModelRun, getDatasetFilterConfigUrl } from 'views/api-configuration/default';
import api from 'views/api-configuration/api';
import { resetStateAction } from 'store/actions';

const initialState = {
    scroll: 0,
    screens: null,
    forms: null,
    actionButtons: null,
    publishedMlModel: null,
    datasetFiltersConfig: null
};

const slice = createSlice({
    name: 'globe',
    initialState,
    reducers: {
        // Testing Reducer
        scroll(state, action) {
            state.scroll = action.payload;
        },

        setScreens(state, action) {
            state.screens = action.payload;
        },

        setForms(state, action) {
            state.forms = action.payload;
        },

        setActionBtns(state, action) {
            state.actionButtons = action.payload;
        },

        setPublishedMlModel(state, action) {
            state.publishedMlModel = action.payload;
        },

        getDatasetFilterConfig(state, action) {
            state.datasetFiltersConfig = action.payload;
        },
        resetState: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(resetStateAction, () => initialState);
    }
});

export function moveScroll(scrollTop) {
    return async () => {
        try {
            dispatch(slice.actions.scroll(scrollTop));
        } catch (error) {
            console.log('error', error);
            // dispatch(slice.actions.hasError(error));
        }
    };
}

// export function setScreens(productclientdatasetsid) {
//     return async () => {
//         try {
//             const response = await axios.get(`${getCustomScreenNames}/${productclientdatasetsid}`, { headers: GetAccessToken() });
//             dispatch(slice.actions.setScreens(response.data.result));
//         } catch (error) {
//             console.log('getCumulativeDataResponse', error);
//             // dispatch(slice.actions.hasError(error));
//         }
//     };
// }

// export function setForms(productclientdatasetsid) {
//     return async () => {
//         try {
//             const response = await axios.get(`${getCustomFormsByprodclidsid(productclientdatasetsid)}`, { headers: GetAccessToken() });
//             dispatch(slice.actions.setForms(response.data.result));
//         } catch (error) {
//             console.log('getCumulativeDataResponse', error);
//             // dispatch(slice.actions.hasError(error));
//         }
//     };
// }

export function setScreens(productclientdatasetsid) {
    console.log(productclientdatasetsid, 'productclientdatasetsid');
    return async () => {
        try {
            // const response = await axios.get(`${getCustomScreenNames}/${productclientdatasetsid}`, { headers: GetAccessToken() });
            dispatch(slice.actions.setScreens(productclientdatasetsid));
        } catch (error) {
            console.log('getCumulativeDataResponse', error);
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function setForms(productclientdatasetsid) {
    console.log(productclientdatasetsid, 'setForms');

    return async () => {
        try {
            //   const response = await axios.get(`${getCustomFormsByprodclidsid(productclientdatasetsid)}`, { headers: GetAccessToken() });
            dispatch(slice.actions.setForms(productclientdatasetsid));
        } catch (error) {
            console.log('getCumulativeDataResponse', error);
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function setButtons(productclientdatasetsid) {
    return async () => {
        try {
            // const response = await axios.get(`${getCatalogButton(productclientdatasetsid)}`, { headers: GetAccessToken() });
            const response = await api.get(`${getCatalogButton(productclientdatasetsid)}`);

            console.log(response, 'setButtons');
            dispatch(slice.actions.setActionBtns(response.data.result));
        } catch (error) {
            console.log('getCumulativeDataResponse', error);
        }
    };
}

export function getPublishMlModel(productclientdatasetsid) {
    return async () => {
        try {
            // const response = await axios.get(`${getPublishedMlModelAndMlModelRun}${productclientdatasetsid}`, {
            //     headers: GetAccessToken()
            // });
            const response = await api.get(`${getPublishedMlModelAndMlModelRun}${productclientdatasetsid}`);
            console.log(response, 'getPublishMlModel');

            dispatch(slice.actions.setPublishedMlModel(response.data.result));
        } catch (error) {
            console.log('getCumulativeDataResponse', error);
        }
    };
}

export function getDatasetFilterConfig(productclientdatasetsid) {
    return async () => {
        try {
            // const response = await axios.get(`${getDatasetFilterConfigUrl}${productclientdatasetsid}`, {
            //     headers: GetAccessToken()
            // });

            const response = await api.get(`${getDatasetFilterConfigUrl}${productclientdatasetsid}`);
            console.log(response, 'getDatasetFilterConfig');

            dispatch(slice.actions.getDatasetFilterConfig(response.data.result));
        } catch (error) {
            console.log('getCumulativeDataResponse', error);
        }
    };
}

export default slice.reducer;

// export const { scroll } = slice.actions;
export const { resetState: resetAppGlobeState } = slice.actions;
