import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'store';

// project imports
import NavItem from 'layout/MainLayout/Sidebar/MenuList/NavItem';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */

const AppBasedScreenNavItems = ({ appChck, keyId }) => {
    const { screens } = useSelector((state) => state.globe);

    let output = <></>;

    if (appChck) {
        output = (
            <>
                {/* {console.log('screens', screens)} */}
                {screens?.map((screen, ind) => {
                    const isScreenStyleEmpty = screen && (!screen.style || Object.keys(screen?.style).length === 0);

                    const item = {
                        id: `custom-dashbaord-screen-${ind}`,
                        title: `${screen.screentitle}`,
                        type: 'custom-dashboard-items',
                        url: `/show-dashboard/${screen.screenid}`,
                        icon: null
                    };
                    if (!isScreenStyleEmpty) {
                        try {
                            const style = JSON.parse(screen.style);
                            console.log(style, 'styleee');
                            item.icon = style.icon;
                        } catch (error) {
                            console.error(`Error parsing style for screen ${screen.screentitle}:`, error);
                        }
                    }
                    return (
                        <React.Fragment key={`${keyId}-screen-${ind}`}>
                            <NavItem key={`form-nav-item-${ind}`} item={item} level={1} />
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
    return output;
};

const AppBasedFormNavItems = ({ appChck, keyId }) => {
    const { forms } = useSelector((state) => state.globe);
    console.log(forms, 'forms');
    let output = <></>;

    if (appChck) {
        output = (
            <>
                {forms?.map((form, ind) => {
                    const isFormStyleEmpty = form && (!form.style || Object.keys(form?.style).length === 0);
                    const item = {
                        id: `custom-forms-${ind}`,
                        title: form.formtitle,
                        type: 'custom-form-items',
                        url: `/data-entry/${form.formid}`,
                        icon: null
                    };
                    if (!isFormStyleEmpty) {
                        try {
                            const style = JSON.parse(form.style);
                            item.icon = style.icon;
                        } catch (error) {
                            console.error(`Error parsing style for screen ${form.formtitle}:`, error);
                        }
                    }

                    return (
                        <React.Fragment key={`${keyId}-form-${ind}`}>
                            <NavItem key={`form-nav-item-${ind}`} item={item} level={1} />
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
    return output;
};

const RoleBasedAuthorizationHOC = ({ children, rolesList, level, appCheck, menuItemType, userApp, keysId }) => {
    let output = <></>;

    const role = useSelector((state) => state.authorization.penetration_role);

    if (role === 'USER@APP' && userApp === 'USER@APP') {
        output = children;
    } else if (rolesList?.includes(role)) {
        if (menuItemType === 'custom-dashboard-items') {
            output = <AppBasedScreenNavItems appChck={appCheck} keysId={keysId} />;
        } else if (menuItemType === 'custom-form-items') {
            output = <AppBasedFormNavItems appChck={appCheck} keysId={keysId} />;
        } else {
            output = children;
        }
    } else {
        output = <></>;
    }

    return output;
};

export default RoleBasedAuthorizationHOC;
