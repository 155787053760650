import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import RoleBasedAuthorizationHOC from 'utils/route-guard/RoleBasedAuthorizationHOC';
import PermBasedAuthorizationHOC from 'utils/route-guard/PermBasedAuthorizationHOC';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ keysId, item }) => {
    const theme = useTheme();
    const { drawerOpen } = useSelector((state) => state.menu);
    const items = item.children?.map((menu, index) => {
        switch (menu.type) {
            case 'collapse':
                return (
                    <>
                        <React.Fragment key={`collapse-${index}`}>
                            <PermBasedAuthorizationHOC perm={menu.perms} nav="Yes">
                                <NavCollapse keysId={`${keysId}-collapse-${index}-${menu.perms}`} menu={menu} level={1} />
                            </PermBasedAuthorizationHOC>
                        </React.Fragment>
                    </>
                );
            case 'item':
                return (
                    <>
                        <React.Fragment key={`-item-${index}`}>
                            <RoleBasedAuthorizationHOC key={`${keysId}-item-${index}`} rolesList={menu.roles}>
                                <PermBasedAuthorizationHOC perm={menu.perms} nav="Yes">
                                    <NavItem key={menu.id} item={menu} level={1} />
                                </PermBasedAuthorizationHOC>
                            </RoleBasedAuthorizationHOC>
                        </React.Fragment>
                    </>
                );
            case 'custom-form-items':
                return (
                    <React.Fragment key={`-custom-form-items-${index}`}>
                        <RoleBasedAuthorizationHOC rolesList={menu.roles} level={0} appCheck="Yes" menuItemType="custom-form-items">
                            <NavCollapse keysId={`${keysId}-custom-form-items-${index}-${menu.perms}`} menu={item} level={1} />
                        </RoleBasedAuthorizationHOC>
                    </React.Fragment>
                );
            case 'custom-dashboard-items':
                return (
                    <React.Fragment key={`-custom-dashboard-items-${index}`}>
                        <RoleBasedAuthorizationHOC rolesList={menu.roles} level={0} appCheck="Yes" menuItemType="custom-dashboard-items">
                            <NavCollapse keysId={`${keysId}-custom-dashboard-items-${index}-${menu.roles}`} menu={item} level={1} />
                        </RoleBasedAuthorizationHOC>
                    </React.Fragment>
                );
            case 'noPerms':
                return (
                    <React.Fragment key={`-noPerms-${index}`}>
                        <RoleBasedAuthorizationHOC rolesList={menu.roles}>
                            <NavItem key={`${keysId}-noPerms-${index}-${menu.roles}`} item={menu} level={1} />
                        </RoleBasedAuthorizationHOC>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment key={`-default-${index}`}>
                        <Typography key={`${keysId}-default-${index}`} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    </React.Fragment>
                );
        }
    });

    return (
        <React.Fragment key={`${item}`}>
            <List
                key={`${item}`}
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {drawerOpen && item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {drawerOpen && item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </React.Fragment>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
