const FEATURE_RESOURCE = {
    RAW: 'raw',
    FEATURE: 'feature',
    ML_MODEL: 'mlmodel',
    ALL: 'all'
};

export const TOOLTIP_TITLE = {
    RELOAD_GRID: 'Reload Grid',
    FILTER: 'Filter'
};

export const titleStyles = {
    fontSize: '18px',
    fontWeight: 800
};
export default FEATURE_RESOURCE;
