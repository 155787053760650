import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, Grid, Button, InputLabel, CircularProgress, Box, Typography } from '@mui/material';
import api from 'views/api-configuration/api';
import { useTheme } from '@mui/material/styles';

import { getDataByMatchingValue, getcolumnDataDisByStage, updateFailedRule, getFormattedDatetime } from 'views/api-configuration/default';
import { useParams } from 'react-router-dom';
import { useSelector } from 'store';

const DataQualityFixation = () => {
    const { dataset, id, attribname, value, tbleId, dqRuleId } = useParams();
    const [selectedColumn, setSelectedColumn] = useState(null);
    const [tableHeader, setTableHeader] = useState([]);

    const [tableData, setTableData] = useState([]);
    const [columnDis, setColumnDis] = useState(null);

    const [error, setError] = useState(null);
    const [uuids, setuuids] = useState(null);
    const [selectedUuids, setSelecteduuids] = useState(null);

    const [loading, setLoading] = useState(false);

    const { rawDataSources } = useSelector((state) => state.dataCollection);
    const handleSelectedDistinctValue = (e) => {
        setSelectedColumn(e.target.value);
    };

    const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
        const ids = rowsSelected.map((index) => tableData[index].uuid_identifier_da_an_v1);
        setSelecteduuids(ids);
    };
    let noMatchContent;
    if (error) {
        noMatchContent = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    } else if (loading) {
        noMatchContent = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Box>
        );
    } else {
        noMatchContent = 'No Records Available';
    }
    const options = {
        selectableRowsVisibleOnly: false,
        onRowSelectionChange,
        tableBodyMaxHeight: '380px',
        elevation: 0,
        responsive: 'standard',
        // responsive: 'stacked',
        filter: true,
        download: false,
        sort: false,
        print: false,
        selectableRows: 'multiple',
        textLabels: {
            body: {
                noMatch: noMatchContent
            }
        },
        pagination: true

        // confirmFilters: true,
    };
    const fetchData = async (limit, datasetid, tableid) => {
        setLoading(true);
        try {
            const parts = id.split('_');
            const prdctid = parts[0];
            const tableId = parts[1];
            setLoading(true);
            const colmnDataDis = await api.get(getcolumnDataDisByStage(dataset, tbleId, 'SILVER'));
            const distinctValuesString = colmnDataDis.data.result[0];
            const distinctValuesObj = JSON.parse(distinctValuesString.distinctValues);
            const distinctValueKeys = Object.keys(distinctValuesObj);
            setColumnDis(distinctValueKeys);
            const response = await api.get(getDataByMatchingValue(prdctid, tableId, id, attribname, value));
            const parsedData = response.data.data.map((item) => JSON.parse(item));
            const data = response.data.data;
            const uuidsArray = await response.data.uuids;
            setuuids(uuidsArray);
            setTableData(parsedData);

            const firstItem = data[0];
            const tableColumns = Object.keys(JSON.parse(firstItem))
                .filter((key) => key !== 'uuid_identifier_da_an_v1' && key !== 'partition_xan')
                .map((key) => ({
                    name: key,
                    label: key
                        .replace(/_xan/g, '')
                        .replace(/timestamp_identifier_da_an_v1/g, 'Timestamp')
                        .replace(/([_])/g, ' ')
                        .replace(/^./, (str) => str.toUpperCase()),
                    options: {
                        filter: true,
                        sort: true,
                        wrap: true,
                        width: 300,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (key === 'timestamp_identifier_da_an_v1') {
                                return <div style={{ width: 80 * 4 }}>{getFormattedDatetime(value)}</div>;
                            }
                            return <div style={{ width: 80 * 4 }}>{value}</div>;
                        }
                    }
                }));

            setTableHeader(tableColumns);
        } catch (error) {
            setLoading(false);
            setError('Feature Not Available At The Moment');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const titleStyles = {
        fontSize: '18px',
        fontWeight: 800
    };
    const handleApplyForAll = async () => {
        try {
            const payload = {
                currentAttributeValue: value,
                newAttributeValue: selectedColumn,
                ruleid: dqRuleId,
                uuids
            };
            const resp = api.put(updateFailedRule, { payload });
        } catch {
            console.log('error');
        }
    };
    const handleApply = () => {
        const payload = {
            currentAttributeValue: value,
            newAttributeValue: selectedColumn,
            ruleid: dqRuleId,
            selectedUuids
        };
        const resp = api.put(updateFailedRule, { payload });
    };
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '15px',
                            borderRadius: '8px',
                            borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            border: '1px solid'
                        }}
                    >
                        <Typography> Incorrect Data: {value}</Typography>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <FormControl
                        sx={{ m: 1, minWidth: 200 }}
                        style={{
                            display: 'block',
                            width: '100%',
                            marginBottom: '8px',
                            paddingLeft: '0px',
                            marginLeft: '0px'
                        }}
                    >
                        <InputLabel id="select-correct-data">Select Correct Data</InputLabel>

                        <Select
                            labelId="select-correct-data"
                            id="select-correct-datat"
                            name="select-correct-data"
                            value={selectedColumn}
                            fullWidth
                            onChange={(e) => {
                                handleSelectedDistinctValue(e);
                            }}
                        >
                            {columnDis?.map((menuItem) => (
                                <MenuItem key={`${menuItem}`} value={`${menuItem}`}>
                                    {menuItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ width: 100, marginTop: 2 }}
                        disabled={selectedUuids === null}
                        onClick={handleApply}
                    >
                        Apply
                    </Button>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ width: 100, marginTop: 2 }}
                        disabled={uuids === null}
                        onClick={handleApplyForAll}
                    >
                        Apply For All
                    </Button>
                </Grid>
            </Grid>
            <MUIDataTable title="Quality Controller" data={tableData} columns={tableHeader} options={options} />;
        </>
    );
};

export default DataQualityFixation;
